

import { Cart, syncingCart, hasPendingOperations, closeCart } from '@yggdrasil/Cart'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CartReview extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



    disableCheckoutButton(disable = true) {
      this.disableCheckout = disable
      const checkoutButtonEl = this.element.querySelector('.elCartReviewCheckoutButton > a')
      checkoutButtonEl?.setAttribute("data-disabled", disable)
    }

    hideSubtotalAmount(hide = true) {
      const subtotalTextEl = this.element.querySelector('.elCartReviewSubtotalText')
      if (subtotalTextEl && hide) {
        subtotalTextEl.innerText = "--"
      }
    }

    mount() {
      Cart.stores.cartData.listen((cartData) => {
        this.render(cartData)
        if (cartData.items.length == 0) {
          closeCart()
        }
        this.disableCheckoutButton(this.disableCheckout)
      })

      syncingCart.listen(() => {
        this.disableCheckoutButton(hasPendingOperations())
        this.hideSubtotalAmount(hasPendingOperations())
      })

      this.element.addEventListener('click', (evt) => {
        const closestItemEl = evt.target.closest('.elCartReviewItemWrapper')
        if (!closestItemEl) return true

        const lineItem = Cart.findItemByUniqueId(closestItemEl.dataset.lineItemUniqueId)
        if (!lineItem) return
        const minusButtontEl = closestItemEl.querySelector('.elCartReviewItemQuantityMinus')
        const plusButtonEl = closestItemEl.querySelector('.elCartReviewItemQuantityPlus')
        const removeButtonEl = closestItemEl.querySelector('.elCartReviewItemRemove')

        if (minusButtontEl.contains(evt.target) || minusButtontEl === evt.target) {
          const op = Cart.decrementOperation(lineItem)
          if (op) this.disableCheckoutButton()
        } else if (plusButtonEl.contains(evt.target) || plusButtonEl === evt.target) {
          const op = Cart.incrementOperation(lineItem)
          if (op) this.disableCheckoutButton()
        } else if (removeButtonEl.contains(evt.target) || removeButtonEl === evt.target) {
          const op = Cart.removeOperation(lineItem)
          if (op) this.disableCheckoutButton()
        } 
      })

      this.element.addEventListener('focusout', (evt) => {
        if (!evt.target.classList.contains('elCartReviewItemQuantityInput')) return true

        const closestItemEl = evt.target.closest('.elCartReviewItemWrapper')
        if (!closestItemEl) return true

        const lineItem = Cart.findItemByUniqueId(closestItemEl.dataset.lineItemUniqueId)
        if (!lineItem) return

        const newQuantity = Number(evt.target.value)
        if (newQuantity !== lineItem.quantity) {
          const op = Cart.upsertOperation(lineItem, newQuantity)
          if (op) {
            this.disableCheckoutButton()
          } else {
            evt.target.value = lineItem.quantity
          }
        }
      })

      this.element.addEventListener('keydown', (evt) => {
        if (
          !(
            (evt.keyCode > 95 && evt.keyCode < 106) ||
            (evt.keyCode > 47 && evt.keyCode < 58) ||
            (evt.keyCode > 36 && evt.keyCode < 41) ||
            evt.keyCode == 8
          )
        ) {
          evt.preventDefault()
        }
      })
    }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const items = this.items ?? [] ;
const button_layout_size = this.button_layout_size ?? null ;
const display_price_subtotals = this.display_price_subtotals ?? null ;

    const header_text = this.element.getAttribute('data-param-header_text');
const continue_shopping_text = this.element.getAttribute('data-param-continue_shopping_text');
const checkout_text = this.element.getAttribute('data-param-checkout_text');
const remove_text = this.element.getAttribute('data-param-remove_text');
const empty_cart_title = this.element.getAttribute('data-param-empty_cart_title');
const empty_cart_desc = this.element.getAttribute('data-param-empty_cart_desc');
const editorPreviewBlankCart = this.element.getAttribute('data-param-editorPreviewBlankCart');
  
      let html = '';
      {
        html += `<div class="elCartReviewItems">`
        const items = globalResourceData?.cart?.items;
        const cart_summary = globalResourceData?.cart?.summary;
        if (items?.length == 0) {
          html += `<div class="elCartReviewEmpty"><i class="fas fa-cart-plus"></i><div class="elCartReviewEmptyTextWrapper"><span class="elCartReviewEmptyTitle">${empty_cart_title}</span><span class="elCartReviewEmptyDesc">${empty_cart_desc}</span></div></div>`
        } else {
          const c0 = items
          const fl1 = new CF2ForloopDrop(c0.length)
          for (const item of c0) {
            const forloop = fl1
            const line_item_unique_id = item?.product_id.toString().concat("-").toString().concat(item.variant_id).toString().concat("-").toString().concat(item.price_id);
            html += `<div class="elCartReviewItemWrapper" data-line-item-unique-id="${line_item_unique_id}">`
            const product = globalResourceData?.productsById?.[item?.product_id];
            const variant = globalResourceData?.variantsById?.[item?.variant_id];
            const price = globalResourceData?.pricesById?.[item?.price_id];
            const image_url = variant?.image ?? product.image;
            let variantNames = "";
            const c2 = variant.property_value_ids
            const fl3 = new CF2ForloopDrop(c2.length)
            for (const prop_id of c2) {
              const forloop = fl3
              variantNames = variantNames.toString().concat(product.all_properties_values[prop_id]);
              if (forloop?.last == false) {
                variantNames = variantNames.toString().concat(", ");
              }
              forloop.next();
            }
            if (image_url && image_url != "") {
              html += `<a class="elCartReviewImageLink" href="${product?.url}"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
              if (true && CF2Blank( image_url ) && CF2Blank( null ) && false == false) {
                html += ` forceHide`
              }
              html += `" data-liquid-replace="item">`
              if (image_url || !CF2Blank( null )) {
                html += `<img class="elImage" src="${image_url ?? null}"/>`
              } else if (false) {
                html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
              }
              html += `</div></a>`
            }
            html += `<div class="elCartReviewItem"><div class="elCartReviewItemDetails"><div><span class="elCartReviewItemName"><a href="${product?.url}">${product?.name}</a></span>`
            if (variantNames && variantNames != "") {
              html += `<span class="elCartReviewItemVariant">${variantNames}</span>`
            }
            html += `</div><span class="elCartReviewItemPrice">${price?.name}</span></div><div class="elCartReviewItemActions"><div class="elCartReviewItemQuantity"><div class="elCartReviewItemQuantityMinus"><span>_</span></div><input class="elCartReviewItemQuantityInput" value="${item?.quantity}"/><div class="elCartReviewItemQuantityPlus"><span>+</span></div></div><span class="elCartReviewItemRemove">${remove_text}</span></div></div></div>`
            forloop.next();
          }
        }
        if (display_price_subtotals && items?.length > 0) {
          html += `<div class="elCartReviewSubtotal"> Subtotal (${cart_summary?.total_quantity} items) <span class="elCartReviewSubtotalText">${cart_summary?.display_sub_total}</span></div>`
        }
        html += `</div>`
        if (items?.length > 0 && editorPreviewBlankCart != true) {
          html += `<div data-page-element="Divider/V1" class="elDividerWrapper id-Divider/V1"><div class="elDivider"></div></div>`
        }
        html += `<div class="elCartReviewButtonsWrapper"><div data-page-element="Button/V1" class="elBTN elCartReviewContinueShoppingButton id-Button/V1`
        if (button_layout_size == "xs") {
          html += ` elCartReviewButtonExtraSmall`
        }
        if (button_layout_size == "s") {
          html += ` elCartReviewButtonSmall`
        }
        if (button_layout_size == "m") {
          html += ` elCartReviewButtonMedium`
        }
        if (button_layout_size == "l") {
          html += ` elCartReviewButtonLarge`
        }
        if (button_layout_size == "xl") {
          html += ` elCartReviewButtonExtraLarge`
        }
        html += `" data-liquid-replace="item"><a target="_self" href="#close-cart" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${continue_shopping_text}</span></span><span class="elButtonSub"></span></a></div>`
        if (items?.length > 0 && editorPreviewBlankCart != true) {
          html += `<div data-page-element="Button/V1" class="elBTN elCartReviewCheckoutButton id-Button/V1`
          if (button_layout_size == "xs") {
            html += ` elCartReviewButtonExtraSmall`
          }
          if (button_layout_size == "s") {
            html += ` elCartReviewButtonSmall`
          }
          if (button_layout_size == "m") {
            html += ` elCartReviewButtonMedium`
          }
          if (button_layout_size == "l") {
            html += ` elCartReviewButtonLarge`
          }
          if (button_layout_size == "xl") {
            html += ` elCartReviewButtonExtraLarge`
          }
          html += `" data-liquid-replace="item"><a target="_self" href="/stores/checkout" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">${checkout_text}</span></span><span class="elButtonSub"></span></a></div>`
        }
        html += `</div>`

      }

      this.replaceContent(html) 

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('CartReview', CartReview)
window["CartReview"] = CartReview

